export const catFileMap = {
  'online-shopping': 'general',
  fashion: 'fashion',
  food: 'food',
  'beauty-health': 'health',
  'electronics-interior': 'interior',
  travel: 'travel',
  'digital-content': 'digital',
  'other-services': 'others',
};

export const newsCategoryMap = {
  all: '全て',
  event_info: 'イベント情報',
  merchant_info: '加盟店情報',
  campaign_info: 'キャンペーン情報',
  csr_info: 'CSR情報',
  press: 'プレスリリース',
  ir: 'IR情報',
  media: 'メディア掲載情報',
  others: 'その他',
};

export const titlePathnameMap = {
  '/': 'トップ',
  '/apple/': 'ペイディあと払いプランApple専用',
  '/payments/': '使い方',
  '/shop/': '使えるお店',
  '/paidycard/': 'ペイディカード',
  '/campaign/': 'キャンペーン',
  '/column/': 'お知らせ',
  '/company/': '会社プロフィール',
  '/merchant/': '法人のお客様',
  '/security/': 'セキュリティ',
  '/guide/': 'ご利用ガイド',
  '/landing/': '総合トップ',
  '/landing_ekyc/': '総合トップ',
  '/landing_ekyc': '総合トップ',
  '/faq/': 'よくあるご質問',
  '/merchant/contact_us/': 'ご相談のお申し込み',
  '/merchant/industry/goods/': '物販',
  '/merchant/industry/digital/': 'デジタルコンテンツ',
  '/merchant/industry/travel/': 'トラベル・アクティビティ',
  '/merchant/industry/subscription/': 'サブスクリプション',
  '/merchant/application/': '導入の流れ',
  '/merchant/case_study/': '導入事例',
  '/merchant/materials/white_paper/': 'お役立ち情報',
  '/merchant/materials/sales/': '資料請求',
  '/merchant/materials/faq/': 'よくあるご質問',
  '/media_center/': 'メディアセンタートップ',
  '/media_center/article/': '論文',
  '/media_center/fun/': 'Paidyファン',
  '/media_center/press/': 'Paidyプレス',
  '/media_center/work_life/': 'Paidyワーク&ライフ',
  '/media_center/work_life/hr/': 'HRブログ',
  '/media_center/work_life/engineering/': 'エンジニアブログ',
  '/landing_6Pay/': '総合トップ',
  '/landing_6Pay': '総合トップ',
  '/landing_6-Pay_ekyc/': '総合トップ',
  '/landing_6-Pay_ekyc': '総合トップ',
};

export const CONTENTFUL_DEFAULT_SPACE = 'i7it8bepy72j';
export const CONTENTFUL_MYPAIDY_SPACE = '9o41oqhppz7q';
export const CONTENTFUL_PATH =
  process.env.GATSBY_ENVIRONMENT === 'production'
    ? '/contentful-data/'
    : '/contentful-data/';
// : '/preview/';
export const CAMPAIGN_DATA_PATH = '/campaign/';
export const NUMBER_OF_PICKS = 1;
export const STEP = 3;
export const MAX_NUMBER_OF_FIRST_CAROUSEL = 8;
export const MAX_NUMBER_OF_SECOND_CAROUSEL = 8;

export const CONTACT_US_ENDPOINT = process.env.GATSBY_CONTACT_US_ENDPOINT;

export const HUBSPOT_EMAIL_GET_SUBSCRIPTION_TYPES_ENDPOINT =
  process.env.GATSBY_HUBSPOT_EMAIL_GET_SUBSCRIPTION_TYPES_ENDPOINT;
export const HUBSPOT_EMAIL_UNSUBSCRIBE_ENDPOINT =
  process.env.GATSBY_HUBSPOT_EMAIL_UNSUBSCRIBE_ENDPOINT;

export const APPLE_LINKS = {
  WEBSITE: 'https://www.apple.com/jp/shop',
  STORE: 'https://www.apple.com/jp/retail/',
};

export const SET_PASSKEY_URL =
  process.env.GATSBY_ENVIRONMENT === 'production'
    ? 'https://d9r4z.app.goo.gl/?link=https://paidy.com/passkey-marketing-email&apn=com.paidy.paidy&isi=1220373112&ibi=com.paidy.Paidy&ofl=https://my.paidy.com/passkey/wrong-device&ifl=https://apps.apple.com/jp/app/paidy/id1220373112&afl=https://play.google.com/store/apps/details?id=com.paidy.paidy&hl=ja'
    : 'https://paidy2.page.link/?link=https://paidy-staging.com/passkey-marketing-email&apn=com.paidy.paidy.stg&isi=1220373112&ibi=com.paidy.Paidy.stg&ofl=https://my.paidy-staging.com/passkey/wrong-device&ifl=https://apps.apple.com/jp/app/paidy/id1220373112&afl=https://play.google.com/store/apps/details?id=com.paidy.paidy&hl=ja';

export const HOME_FAQS = [
  {
    question: 'お支払いについて',
    link:
      'https://cs-support.paidy.com/support/solutions/articles/150000040597-%E6%94%AF%E6%89%95%E3%81%84%E6%96%B9%E6%B3%95%E3%81%8C%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84',
  },
  {
    question: 'ご利用可能額について',
    link:
      'https://cs-support.paidy.com/support/solutions/articles/150000040721-%E5%88%A9%E7%94%A8%E5%8F%AF%E8%83%BD%E9%A1%8D%E3%81%8C%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84',
  },
  {
    question: '手数料について',
    link:
      'https://cs-support.paidy.com/support/solutions/articles/150000040596-%E6%89%8B%E6%95%B0%E6%96%99%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84',
  },
];

export const HOME_TOP_BANNERS = [
  {
    img: require('../images/hero-top-1.png'),
    to: '/payments/',
  },
  {
    img: require('../images/hero-top-2.png'),
    to: '/apple/',
  },
  {
    img: require('../images/hero-top-3.png'),
    to: '/plus/',
  },
];

export const HOME_TOP_BANNERS_SP = [
  {
    img: require('../images/hero-top-1-sp.png'),
    to: '/payments/',
  },
  {
    img: require('../images/hero-top-2-sp.png'),
    to: '/apple/',
  },
  {
    img: require('../images/hero-top-3-sp.png'),
    to: '/plus/',
  },
];

export const PAIDY_HOST =
  process.env.GATSBY_ENVIRONMENT === 'production'
    ? 'https://paidy.com'
    : 'https://paidy-staging.com';

export const PAIDY_DOWNLOAD_HOST =
  process.env.GATSBY_ENVIRONMENT === 'production'
    ? 'https://download.paidy.com'
    : 'https://download.paidy-staging.com';

export const SITE_METADATA = {
  top: {
    title: 'あと払いペイディ｜翌月後払いのPaidy',
    description:
      'あと払いペイディは、クレジットカード不要、事前登録不要。スマホだけで今すぐお買い物できて、お支払いはまとめて翌月10日まででOK。コンビニ払い・銀行振込・口座振替が利用可能。アプリから本人確認すると、分割手数料無料の3・6・12回あと払いや、Visaマークのあるお店でもペイディが使える「ペイディカード」が利用可能になります。',
  },
  payments: {
    title: 'あと払いペイディ｜使い方｜翌月後払いのPaidy',
    description:
      '「あと払いペイディの使い方」では、ペイディの詳しい使い方を紹介。メアドと携帯番号だけでお買い物可能で、お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「ペイディカード」が利用可能になります。',
  },
  paidycard: {
    title: 'あと払いペイディ｜ペイディカード｜翌月後払いのPaidy',
    description:
      'ペイディがもっと便利になる「ペイディカード」を詳しく紹介。ペイディアプリから本人確認をしてペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「ペイディカード」が利用可能になります。',
  },
  shop: {
    title: 'あと払いペイディ｜使えるお店・通販サイト｜翌月後払いのPaidy',
    description:
      'ペイディが使えるお店・通販サイトをご紹介します。AmazonやQoo10、DMMなど、いつもご利用のお店でも、メアドと携帯番号だけでお買い物可能。お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「ペイディカード」が利用可能になります。',
  },
  column: {
    title: 'あと払いペイディ｜お知らせ｜翌月後払いのPaidy',
    description:
      'あと払いペイディの機能や使い方をご紹介。ペイディならメアドと携帯番号だけでお買い物可能で、お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「ペイディカード」が利用可能になります。',
  },
  campaign: {
    title: 'あと払いペイディのお得なキャンペーン情報｜翌月後払いのPaidy',
    description:
      'あと払いペイディのお得なキャンペーン情報を紹介。ペイディならメアドと携帯番号だけでお買い物可能で、お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「ペイディカード」が利用可能になります。',
  },
  apple: {
    title:
      'あと払いペイディ｜ペイディあと払いプランApple専用｜翌月後払いのPaidy',
    description:
      'ペイディあと払いプランApple専用は、Appleでの購入専用の特別なご利用可能額をご用意。一度登録を完了するだけで、Appleのウェブサイト、または お近くのApple Storeで何度でもご利用可能です。お申し込みは簡単、最短5分で完了します。iPhoneやMac、iPad、Apple Watch、AppleCare、アクセサリなど、Appleで製品を最も賢く購入する方法のひとつです。',
  },
  security: {
    title: 'あと払いペイディ｜安全への取り組み｜翌月後払いのPaidy',
    description:
      'あと払いペイディの安全への取り組みをご紹介。ペイディでは、お客様に安心・安全にお買い物を楽しんでいただくために、様々な取り組みをしています。認証コードによる二段階認証・本人確認の徹底・24時間365日体制で監視。今後もセキュリティ対策を強化していきます。',
  },
  company: {
    title: '会社プロフィール｜株式会社Paidy',
    description:
      '株式会社Paidyのマネジメントチームや会社情報、出資企業、IR/投資家情報など、会社プロフィールを掲載しています。',
  },
  press: {
    title: 'プレスリリース｜株式会社Paidy',
    description: '株式会社Paidyのプレスリリースを掲載しています。',
  },
  passkeys: {
    title:
      'あと払いペイディ｜パスキーでログインをもっとかんたんで安全に｜翌月後払いのPaidy',
    description:
      '新しいログイン方法「パスキー」を設定すると、生体認証やパスコード、パターンなどでかんたんにログインできます。メールアドレスと携帯電話番号の入力は不要。安全性も高く、安心してご利用いただけます。',
  },
  recruit: {
    title: '採用情報｜株式会社Paidy',
    description:
      'Paidyは、約150名の社員の出身が30の国と地域というマルチインターナショナルでグローバルな組織です。我々のパーパスである”夢に自信を、心に余裕を持てる世界を作る”ことを目指して、一緒に会社を成長させる情熱に満ちた仲間を世界中から募集しています。我々のビジネス、プロダクトおよび組織にご興味をお持ちいただけましたら、ぜひご応募を検討ください。',
  },
  merchant: {
    title: 'あと払いペイディ｜法人のお客様｜株式会社Paidy',
    description:
      'クレジットカードのいらないあと払い決済「ペイディ」なら、ユーザーのニーズに合わせた決済でカゴ落ちを防ぎ、売上アップが見込めます。',
  },
  merchantApplication: {
    title: 'あと払いペイディ｜導入の流れ｜株式会社Paidy',
    description:
      'あと払いペイディの導入の流れをご紹介。クレジットカードのいらないあと払い決済「ペイディ」なら、ユーザーのニーズに合わせた決済でカゴ落ちを防ぎ、売上アップが見込めます。',
  },
  merchantContact: {
    title: 'あと払いペイディ｜導入のご相談｜株式会社Paidy',
    description:
      'あと払いペイディの導入のご相談はこちらから。クレジットカードのいらないあと払い決済「ペイディ」なら、ユーザーのニーズに合わせた決済でカゴ落ちを防ぎ、売上アップが見込めます。',
  },
  campaign202109: {
    title: 'あと払いペイディ｜TVCM｜翌月後払いのPaidy',
    description:
      '[Alexandros] 川上洋平さん出演のペイディTVCMをご紹介。WEB限定のロングバージョンや、CMのメイキング動画、川上洋平さんのインタビューなど、特別なコンテンツをお楽しみください。',
  },
  crossMerchantNovember2021: {
    title: '秋の新発見キャンペーン',
    description:
      '対象ショップ2店舗以上でペイディを利用してお買い物すると、最大1,000円キャッシュバック！',
  },
  postOfficeJP: {
    title: 'あと払いペイディ｜翌月後払いのPaidy',
    description:
      '対象ショップ2店舗以上でペイディを利用してお買い物すると、最大1,000円キャッシュバック！',
  },
  plus3Pay: {
    title: 'ペイディ３回あと払い',
    description:
      '欲しいものがあるけど、ちょっとだけ手が届かない…\nそんな時は、ペイディの３回あと払い！分割手数料無料*\n*口座振替・銀行振込のみ無料',
  },
  merchantCampaignNewlife: {
    title: '＼お買い物からはじまる／ 新生活にエールキャンペーン',
    description: '対象ショップで6,000円以上初利用なら500円キャッシュバック',
  },
  merchantCampaignTravelSrping: {
    title:
      '「行きたいところに今すぐ行こう！おでかけ応援キャッシュバック」キャンペーン',
    description:
      'キャンペーン期間中に対象ショップの決済画面でペイディの翌月あと払いもしくは3回あと払いを利用すると、一店舗ごとにもれなく100円キャッシュバック。さらに抽選で、期間中の対象ショップでの最高額のお買い物について全額（最大10万円）キャッシュバック。',
  },
  merchantCampaignTravelsSummerVol1: {
    title:
      'ホテルや飛行機をペイディで予約すると抽選で全額（最大10万円）キャッシュバック！',
    description:
      'キャンペーン期間中に対象ショップの決済画面でペイディの翌月あと払いもしくは3回あと払いを利用すると、抽選で、期間中の対象ショップでの最高額のお買い物について全額（最大10万円）キャッシュバック。',
  },
  landing: {
    virtualCard: {
      title: 'ペイディカードを最短5分で即日・無料発行。',
      description: 'ネットショップ*で今すぐ使える。',
    },
    furusatochoice: {
      title: 'furusatochoice',
      description: 'furusatochoice',
    },
  },
  choatobarai: {
    title: 'SHOPLIST超あと払い | Paidy',
    description:
      'Paidy(ペイディー)はオンラインショップで最もカンタンにご利用いただける決済サービスです。事前登録やクレジットカードは必要なく、携帯電話番号とメールアドレスのみで決済いただけます。お近くのコンビニまたは銀行で、まとめて月1回お支払いください。',
  },
  general_cashback_july: {
    title:
      '「期間中ペイディを利用すると、抽選で最大10万円キャッシュバック！」キャンペーン',
    description:
      '宮城県、熊本県、山口県、長崎県にお住まいの方限定で、キャンペーン期間中に決済画面でペイディの翌月あと払い、３回あと払い、もしくはペイディカードを利用でお買い物すると、抽選で最大10万円キャッシュバックが当たる。',
  },
  general_cashback_202210: {
    title:
      '「期間中にペイディでお買い物すると、抽選で全額 (最大10万円) キャッシュバック！」キャンペーン',
    description:
      'キャンペーン期間中に決済画面でペイディの翌月あと払い、３回あと払い、もしくはペイディカードを利用でお買い物すると、抽選で100名様に全額（最大10万円）キャッシュバックが当たる。',
  },
  general_cashback_202211: {
    title:
      '「期間中にペイディでお買い物すると、抽選で全額 (最大10万円) キャッシュバック！」キャンペーン',
    description:
      'キャンペーン期間中に決済画面でペイディの翌月あと払い、３回あと払い、もしくはペイディカードを利用でお買い物すると、抽選で100名様に全額（最大10万円）キャッシュバックが当たる。',
  },
  general_cashback_202212: {
    title:
      '「今すぐ始めて、最高に自分らしい新年を」抽選で全額 (最大10万円) キャッシュバックキャンペーン',
    description:
      'キャンペーン期間中に決済画面でペイディの翌月あと払い、３回あと払い、もしくはペイディカードを利用でお買い物すると、抽選で100名様に全額（最大10万円）キャッシュバックが当たる。',
  },
  travelsummervol2: {
    title: 'バスやアクティビティをペイディで予約すると5,000円キャッシュバック!',
    description:
      'キャンペーン期間中に対象ショップの決済画面でペイディの翌月あと払いもしくは3回あと払いを利用すると、対象ショップでペイディを初めて利用して5,000円（税込み）以上お買い物をすると500円キャッシュバック。',
  },
  guide: {
    title: 'あと払いペイディ ご利用ガイド　株式会社Paidy',
    description: 'あと払いペイディ ご利用ガイド　株式会社Paidy',
  },
  twitter_2022AAA_202211: {
    title: '「2022 Asia Artist Awards」チケットプレゼントキャンペーン',
    description:
      'ペイディ公式Twitterアカウント「@PaidyJP」をフォローし、ハッシュタグ「#ペイディさん2022AAAで推しに会いたいです」をつけ、引用RTであなたが会いたい推しを投稿してください。参加者の中から抽選で、「2022 Asia Artist Awards」チケットを50組(100名様)にプレゼントいたします。',
  },
  multiple: {
    title: 'ペイディの３回あと払い',
    description:
      'ペイディで分割手数料無料*の３回あと払いで必要なものをかしこく手に入れて、あなたの毎日をもっとハッピーに。',
  },
  lottery202212: {
    title: 'ペイディ｜フライング新年キャンペーン',
    description:
      'ペイディの使い方はとってもかんたん。来年を待たずに、やりたいことを今すぐ始めよう！',
  },
  futureshop: {
    title: 'ペイディ500円キャッシュバック',
    description:
      'キャンペーン期間中に対象ショップで初めてペイディを利用し、決済画面で翌月あと払いまたは3回あと払いを選択して6,000円（税込）以上お買い物すると、500円キャッシュバック',
  },
  npay: {
    title:
      '分割手数料無料*の3・6・12回あと払いで月々のお支払いを調整｜翌月後払いのPaidy',
    description:
      '分割手数料無料*で、お支払いを3・6・12回に分けることができます。お支払い総額はそのままで月々のお支払いを調整できるので、よりかしこく計画的にお買い物を楽しめます。',
    keywords:
      'amazon 後払い 5400, amazon 分割払い 6600, amazon 3回払い 50, amazon 6回払い 10, アマゾン 後払い 5400, アマゾン 分割払い 6600, ペイディ 後払い 2400, ペイディ 分割払い 1300, ペイディ 3回払い 590, paidy 後払い 880, paidy 分割払い 590, paidy 3回払い 210, amazon 分割払い 手数料無料 90, amazon あと払い 170, ペイディ あと払い 480, paidy あと払い 140',
  },
  landing_6pay: {
    title: '総合トップ',
    description:
      'あと払いペイディなら、スマホだけで今すぐ購入、お支払いは翌月でOK。事前登録不要、クレジットカードの登録不要。本人確認をすると、毎月の予算設定や分割手数料*の３・６回あと払い、ペイディカードなどの便利な機能を使うことができます。*口座振替・銀行振込のみ無料',
  },
  landing_6pay_ekyc: {
    title: '総合トップ',
    description:
      'あと払いペイディなら、スマホだけで今すぐ購入、お支払いは翌月でOK。事前登録不要、クレジットカードの登録不要。本人確認をすると、毎月の予算設定や分割手数料*の３・６回あと払い、ペイディカードなどの便利な機能を使うことができます。*口座振替・銀行振込のみ無料',
  },
  plus_6Pay: {
    title: 'ペイディ３回あと払い',
    description:
      '高額商品をもっとかしこく購入したい。\nそんな時は、ペイディの３・６回あと払い。\n分割手数料無料*\n*口座振替・銀行振込のみ無料',
  },
  landing_12pay: {
    title: '総合トップ',
    description:
      'あと払いペイディなら、スマホだけで今すぐ購入、お支払いは翌月でOK。事前登録不要、クレジットカードの登録不要。本人確認をすると、毎月の予算設定や分割手数料*の3・6・12回あと払い、ペイディカードなどの便利な機能を使うことができます。*口座振替・銀行振込のみ無料',
  },
  landing_12pay_ekyc: {
    title: '総合トップ',
    description:
      'あと払いペイディなら、スマホだけで今すぐ購入、お支払いは翌月でOK。事前登録不要、クレジットカードの登録不要。本人確認をすると、毎月の予算設定や分割手数料*の3・6・12回あと払い、ペイディカードなどの便利な機能を使うことができます。*口座振替・銀行振込のみ無料',
  },
  plus_12Pay: {
    title: 'ペイディ３回あと払い',
    description:
      '高額商品をもっとかしこく購入したい。\nそんな時は、ペイディの３・６・12回あと払い。\n分割手数料無料*\n*口座振替・銀行振込のみ無料',
  },
  campaign202303: {
    title:
      'あと払いペイディ｜INI×ペイディ TVCMスペシャルサイト｜翌月後払いのPaidy',
    description:
      'INI出演のペイディTVCMをご紹介。INIメンバーの壁紙ダウンロードや、お得なキャンペーンなど、特別なコンテンツをお楽しみください。',
  },
  daiseikaicampaign_202404: {
    title: 'あと払いペイディ｜TVCMスペシャルサイト｜翌月後払いのPaidy',
    description:
      'ゆりやんレトリィバァさん & 満島真之介さん出演のペイディTVCMをご紹介。CMのメイキング動画、インタビューなど、特別なコンテンツをお楽しみください。',
  },
  daiseikaicampaign_202404_2: {
    title: 'あと払いペイディ｜TVCMスペシャルサイト｜翌月後払いのPaidy',
    description:
      'ゆりやんレトリィバァさん & 満島真之介さん出演のペイディTVCMをご紹介。CMのメイキング動画、お得なキャンペーンなど、特別なコンテンツをお楽しみください。',
  },
  '10th_anniversary': {
    title: 'あと払いペイディ｜10周年スペシャルサイト｜翌月後払いのPaidy',
    description:
      'ペイディ10周年記念のスペシャルサイト。なえなのさん出演の10周年記念ショートドラマや、ペイディで夢を叶えたお客様の声、お得にお買い物が楽しめるキャンペーン情報などをご紹介。',
  },
  merchant_6pay12pay: {
    title: ' ペイディの6回・12回あと払い',
    description: 'ペイディの6回・12回あと払い申し込みページ',
  },
  general_cashback_202303: {
    title:
      'あと払いペイディ｜INI×ペイディ コラボ記念キャンペーン 抽選で100名様に全額キャッシュバック｜翌月後払いのPaidy',
    description:
      '【INI×ペイディ コラボ記念キャンペーン】期間中にペイディを利用すると、抽選で100名様に全額キャッシュバック（最大10万円）。',
  },
  general_cashback_202305: {
    title:
      'あと払いペイディ｜INI×ペイディ コラボ記念キャンペーン 抽選で100名様に全額キャッシュバック｜翌月後払いのPaidy',
    description:
      '【INI×ペイディ コラボ記念キャンペーン】期間中にペイディを利用すると、抽選で100名様に全額キャッシュバック（最大10万円）。',
  },
  general_cashback_202307: {
    title:
      'あと払いペイディ｜1000万ダウンロードありがとうキャンペーン 抽選で1000名様に最大1万円キャッシュバック｜翌月後払いのPaidy',
    description:
      '【1000万ダウンロードありがとうキャンペーン】期間中にペイディを利用すると、抽選で1000名様に最大1万円キャッシュバック。',
  },
  general_cashback_202309: {
    title:
      'あと払いペイディ｜INI×ペイディ コラボ記念キャンペーン 抽選で100名様に全額キャッシュバック｜翌月後払いのPaidy',
    description:
      '期間中にペイディを利用すると、抽選で100名様に全額キャッシュバック（最大10万円）。',
  },
  general_cashback_202311: {
    title:
      'あと払いペイディ｜年末感謝キャンペーン 抽選で100名様に全額キャッシュバック｜翌月後払いのPaidy',
    description:
      '期間中にペイディを利用すると、抽選で100名様に全額キャッシュバック（最大10万円）。',
  },
  general_cashback_202402: {
    title:
      'あと払いペイディ｜INI×ペイディ コラボ記念キャンペーン 抽選で100名様に全額キャッシュバック｜翌月後払いのPaidy',
    description:
      '期間中にペイディを利用すると、抽選で100名様に全額キャッシュバック（最大10万円）。',
  },
  tvcmlaunch_cashback_202404: {
    title:
      'あと払いペイディ｜TVCMオンエア記念キャンペーン 抽選で100名様に全額キャッシュバック｜翌月後払いのPaidy',
    description:
      '【TVCMオンエア記念キャンペーン】期間中にペイディを利用すると、抽選で100名様に全額キャッシュバック（最大10万円）。',
  },
  lottery202303: {
    title:
      'あと払いペイディ｜INI×ペイディ コラボ記念キャンペーン 抽選で1万名様に1,000円キャッシュバック｜翌月後払いのPaidy',
    description:
      '【INI×ペイディ コラボ記念キャンペーン】期間中に対象ショップであと払い（ペイディ）を利用して1,000円以上購入すると、抽選で1万名様に1,000円キャッシュバック。',
  },
  lottery202303v2: {
    title:
      'あと払いペイディ｜INI×ペイディ コラボ記念キャンペーン 抽選で100名様に全額キャッシュバック｜翌月後払いのPaidy',
    description:
      '【INI×ペイディ コラボ記念キャンペーン】期間中にペイディを利用すると、抽選で100名様に全額キャッシュバック（最大10万円）。',
  },
  campaign_202304_6pay: {
    title:
      'あと払いペイディ｜６回あと払いキャンペーン 最大1,500円キャッシュバック｜翌月後払いのPaidy',
    description:
      '【６回あと払いキャンペーン】キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、初めて分割手数料無料*の６回あと払いで1回あたり6,000円以上購入すると、500円をキャッシュバック。 ',
  },
  incentiveCP_202305: {
    title:
      'あと払いペイディ｜INI×ペイディ 抽選で150名様にINI限定特大ポスタープレゼント｜翌月後払いのPaidy',
    description:
      '【INI限定特大ポスタープレゼントキャンペーン】抽選で150名様にINI限定特大ポスターが当たる！',
  },
  campaign_202307_10MM_merchant_vol1: {
    title:
      'あと払いペイディ｜アプリ1000万ダウンロードありがとう！最大全額キャッシュバックのチャンス！｜翌月後払いのPaidy',
    description:
      '【アプリ1000万ダウンロードありがとう！】キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、キャンペーン期間中に対象ショップの決済画面でペイディを利用すると、抽選でキャッシュバックが当たります。',
  },
  campaign_202307_10MM_merchant_vol2: {
    title:
      'あと払いペイディ｜アプリ1000万ダウンロードありがとう！最大全額キャッシュバックのチャンス！｜翌月後払いのPaidy',
    description:
      '【アプリ1000万ダウンロードありがとう！】キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、キャンペーン期間中に対象ショップの決済画面でペイディを利用すると、抽選でキャッシュバックが当たります。',
  },
  atobaraisodanshitsu: {
    title:
      'あと払いペイディ｜ペイディ加盟店手数料1か月無料キャンペーン｜翌月後払いのPaidy',
    description:
      'あと払いペイディ｜ペイディ加盟店手数料1か月無料キャンペーン｜翌月後払いのPaidy',
  },
  netshopshienshitsu: {
    title:
      'あと払いペイディ｜ペイディ加盟店手数料1か月無料キャンペーン｜翌月後払いのPaidy',
    description:
      '対象期間中にペイディを新規導入すると、1ヶ月分の加盟店手数料が無料となります。',
  },
  campaign_202310_n_pay: {
    title:
      'あと払いペイディ｜6・12回あと払いキャッシュバックキャンペーン！最大２,０００円のチャンス！｜翌月後払いのPaidy',
    description:
      '【6・12回あと払いキャッシュバックキャンペーン！】キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、6もしくは12回あと払いを初めて利用すると最大2,000円のキャッシュバック ',
  },
  campaign_20240701_travel: {
    title:
      'あと払いペイディ｜トラベルキャッシュバックキャンペーン！｜翌月後払いのPaidy',
    description:
      '【トラベルキャッシュバックキャンペーン！】キャンペーン期間中に初めて対象ショップの決済画面で「あと払い（ペイディ）」を選択し、お買い物するとキャッシュバックのチャンス ',
  },
  campaign_20240424_N_paycashback: {
    title:
      'あと払いペイディ｜6回・12回あと払いキャッシュバックキャンペーン！｜翌月後払いのPaidy',
    description:
      '【6回・12回あと払いキャッシュバックキャンペーン！】キャンペーン期間中に初めて対象ショップの決済画面で「あと払い（ペイディ）」を選択し、6回もしくは12回あと払いお買い物するとキャッシュバックのチャンス',
  },
  campaign_20231120_lottery: {
    title:
      'あと払いペイディ｜抽選で全額キャッシュバックキャンペーン！｜翌月後払いのPaidy',
    description:
      '【抽選で全額キャッシュバックキャンペーン！】キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、お買い物すると抽選で全額キャッシュバックのチャンス！ ',
  },
  campaign_20231120_lottery_DLsite: {
    title:
      'あと払いペイディ｜抽選で全額キャッシュバックキャンペーン！｜翌月後払いのPaidy',
    description:
      '【抽選で全額キャッシュバックキャンペーン！】キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、お買い物すると抽選で全額キャッシュバックのチャンス！ ',
  },
  campaign_202311_shopifyplus: {
    title:
      'あと払いペイディ｜ペイディ加盟店手数料3か月無料キャンペーン｜翌月後払いのPaidy',
    description:
      '対象期間中にペイディを新規導入すると、3ヶ月分の加盟店手数料が無料となります。 ',
  },
  campaign_202311_futureshop: {
    title:
      'あと払いペイディ｜ペイディ加盟店手数料1か月無料キャンペーン｜翌月後払いのPaidy',
    description:
      '対象期間中にペイディを新規導入すると、1ヶ月分の加盟店手数料が無料となります。 ',
  },
  campaign_202403_plusshipping: {
    title:
      'あと払いペイディ｜ペイディ加盟店手数料1か月無料キャンペーン｜翌月後払いのPaidy',
    description:
      '対象期間中にペイディを新規導入すると、1ヶ月分の加盟店手数料が無料となります。 ',
  },
  campaign_202404_WW: {
    title:
      'あと払いペイディ｜ペイディ加盟店手数料1か月無料キャンペーン｜翌月後払いのPaidy',
    description:
      '対象期間中にペイディを新規導入すると、1ヶ月分の加盟店手数料が無料となります。 ',
  },
  campaign_202404_huckleberry: {
    title:
      'あと払いペイディ｜ペイディ加盟店手数料1か月無料キャンペーン｜翌月後払いのPaidy',
    description:
      '対象期間中にペイディを新規導入すると、1ヶ月分の加盟店手数料が無料となります。 ',
  },
  campaign_20240815_Lottery: {
    title:
      'あと払いペイディ｜最大3000円のキャッシュバックキャンペーン｜翌月後払いのPaidy',
    description:
      'キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、3000円以上お買い物するとキャッシュバックのチャンス ',
  },
  general_cashback_202407: {
    title:
      'あと払いペイディ｜CMオンエア記念キャンペーン 抽選で100名様に全額キャッシュバック｜翌月後払いのPaidy',
    description:
      '【CMオンエア記念キャンペーン】期間中にペイディを利用すると、抽選で100名様に全額キャッシュバック（最大10万円）。',
  },
  campaign_20240930: {
    title:
      'あと払いペイディ｜10%キャッシュバックキャンペーン｜翌月後払いのPaidy',
    description:
      'あと払い（3回・6回・12回）をそのサイトで初めて利用して10％キャッシュバック* *最大１，０００円',
  },
  campaign_202501_lottery: {
    title:
      'あと払いペイディ｜10%キャッシュバックキャンペーン｜翌月後払いのPaidy',
    description:
      'キャンペーン期間中に対象ショップの決済画面でペイディを選択し、1,000円以上お買い物すると、抽選で3人に1人キャッシュバックが当たります。1等10,000円キャッシュバック、2等500円キャッシュバック、3等100円キャッシュバック。',
  },
  general_cashback_202501: {
    title:
      'あと払いペイディ｜10周年ありがとう！抽選で100名様に最大10万円キャッシュバックキャンペーン｜翌月後払いのPaidy',
    description:
      '【10周年記念キャンペーン】期間中にペイディを利用すると、抽選で100名様に最大10万円キャッシュバック。',
  },
  general_cashback_202503: {
    title:
      'あと払いペイディ｜新生活応援キャンペーン 抽選で100名様に最大10万円キャッシュバック｜翌月後払いのPaidy',
    description:
      '【新生活応援キャンペーン】期間中にペイディを利用すると、抽選で100名様に最大10万円キャッシュバック。',
  },
  campaign_202504_npaycampaign: {
    title:
      'あと払いペイディ｜最大1,500円キャッシュバック！｜翌月あと払いのPaidy',
    description:
      'キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、6回あと払いを初めて利用して6,000円以上購入すると500円キャッシュバック、12回あと払いを初めて利用して12,000円以上購入すると1,000円をキャッシュバック。6回あと払い・12回あと払いそれぞれ1回ずつ対象となり、最大1,500円キャッシュバック。',
  },
};

// Used under paidy.com/ except for under paidy.com/merchant/
export const GTM_ID_PAIDY_COM = 'GTM-K3BK4J7';
// Used under paidy.com/merchant/
export const GTM_ID_MERCHANT = 'GTM-KXH44GM';

export const DYNAMICK_LINK = {
  TOP_PAGE: 'https://paidy.onelink.me/GTiS/topage',
  APP_STORE: 'https://paidy.onelink.me/eV85/PC',
  PLAY_STORE: 'https://paidy.onelink.me/KTFT/PC',
  SHOP_SP: 'https://paidy.onelink.me/GTiS/shopSP',
  SHOP_QR: 'https://paidy.onelink.me/GTiS/shopQR',
  CARDPAGE: 'https://paidy.onelink.me/GTiS/cardpage',
  CARDQR: 'https://paidy.onelink.me/GTiS/cardQR ',
};
